<template>
    <div>
        <div class="custom-file-wrapper mt-3">
            <button v-if="web_cam_status=='started'" type="button" class="btn btn-primary text-light mb-3 w-100"
                    @click="onCapture">Capture
            </button>
            <button v-if="web_cam_status=='captured'" type="button" class="btn btn-primary text-light mb-3 w-100">
                Captured
            </button>
            <button v-if="web_cam_status=='init'" type="button" class="btn btn-primary text-light mb-3 w-100"
                    @click="onStart">
                Start Web Cam
            </button>
        </div>
        <template v-if="$parent.form.image">
            <div class="form-image-preview">
                <img class="form-img img-thumbnail w-100 h-100" :src="$parent.form.image"/>
                <i @click="removeImage" class="close text-danger far fa-times-circle"></i>
            </div>
        </template>
        <template v-else-if="web_cam_status=='started'">
            <vue-web-cam ref="webcam" :device-id="deviceId" width="100%" height="100%"
                         @cameras="onCameras" @camera-change="onCameraChange" class="img-thumbnail"/>
        </template>
        <template v-else>
            <div class="form-image-preview">
                <img class="form-img img-thumbnail w-100 h-100" src="@/assets/default.png"/>
            </div>
        </template>
        <div class="custom-file-wrapper mt-3">
            <input type="file" @change="onFileChange" accept="image/*" capture class="form-control custom-file">
            <div class="btn btn-primary text-light w-100">Browse Image</div>
        </div>
    </div>
</template>
<script>
import {WebCam} from "vue-web-cam";

export default {
    name      : "ImageUploadWithWebCam",
    components: {'vue-web-cam': WebCam},
    data      : () => ({
        camera        : null,
        deviceId      : null,
        devices       : [],
        web_cam_status: 'init',
    }),
    computed  : {
        device: function () {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    watch     : {
        camera : function (id) {
            this.deviceId = id;
        },
        devices: function () {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera   = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },
    methods   : {
        onCapture() {
            this.web_cam_status        = 'captured'
            this.$parent.isImageUpload = true;
            this.$parent.form.image    = this.$refs.webcam.capture();
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.web_cam_status     = 'started';
            this.$parent.form.image = '';
            const component_created = setInterval(() => {
                if (this.$refs['webcam']) {
                    this.$refs.webcam.start();
                    clearInterval(component_created);
                }
            }, 100);
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera   = deviceId;
        },
        // --------------
        onFileChange(e) {
            this.web_cam_status = 'init';
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0]);
        },
        createImage(file) {
            this.$parent.isImageUpload = true;
            var image                  = new Image();
            var reader                 = new FileReader();
            var vm                     = this.$parent;

            reader.onload = (e) => {
                vm.form.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        removeImage: function (e) {
            this.web_cam_status     = 'init';
            this.$parent.form.image = '';
        },
    },
}
</script>
